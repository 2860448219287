import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CompanyData } from '../interface/company';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class CompanyService extends CompanyData {

  constructor(private https: HttpClient) { 
    super();
  }

  list(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/user/company", 
    );
  }

	get(data: any): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/user/company/" + data.id, 
    );
	}

  create(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/user/company/create", 
			data,
    );
	}
	
	update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/user/company/" + data.id, 
			data,
    );
	}
	
	delete(data: any): Observable<any> {
    return this.https.delete(
			environment.apiUrl + "/user/company/" + data.id, 
    );
  }

  getLicenseUsage(data: any): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/dashboard/threshold/" + data.id, 
    );
  }

  updateLicenseUsage(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/dashboard/threshold/save", 
			data,
    );
  }

}
