import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserData2 } from '../interface/users2';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class UserService2 extends UserData2 {

  currentUser: any = null;

  constructor(private https: HttpClient, private router: Router) { 
    super();
  }

  resetPassword(data: any): Observable<any> {
    return this.https.put<any>(
      environment.apiUrl + "/user/user/" + data.id + "/password", 
      data,
    );
  }

  updateMobile(data: any): Observable<any> {
    return this.https.put<any>(
      environment.apiUrl + "/user/user/" + data.id + "/mobile", 
      data,
    );
  }

  searchAisUser(data: any): Observable<any> {
    return this.https.post<any>(
      environment.apiUrl + "/user/user/search/ais", 
      data,
    );
  }

  searchNonAisUser(data: any): Observable<any> {
    return this.https.post<any>(
      environment.apiUrl + "/user/user/search/customer", 
      data,
    );
  }

  get(data: any): Observable<any> {
    return this.https.get<any>(
      environment.apiUrl + "/user/user/" + data.id, 
    );
  }

  create(data: any): Observable<any> {
    return this.https.post<any>(
      environment.apiUrl + "/user/user/create", 
      data,
    );
  }

  update(data: any): Observable<any> {
    return this.https.put<any>(
      environment.apiUrl + "/user/user/" + data.id, 
      data,
    );
  }

  delete(data: any): Observable<any> {
    return this.https.delete<any>(
      environment.apiUrl + "/user/user/" + data.id, 
    );
  }

  getEdit(data: any): Observable<any> {
    return this.https.get<any>(
      environment.apiUrl + "/user/user/edit/" + data.id, 
    );
  }

  exportAis(data: any, filetype: any): Observable<any> {
		let url = `${environment.apiUrl}${"/user/user/export/ais/"}${filetype}`;
		return this.https.post(url, data, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
  }
  
  exportCustomer(data: any, filetype: any): Observable<any> {
		let url = `${environment.apiUrl}${"/user/user/export/customer/"}${filetype}`;
		return this.https.post(url, data, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
	}

  // Own Service

  logon(user: any):  Observable<any> {    
    return this.https.post<any>(
      environment.apiUrl + "/user/api/logon", 
      user
    );
  }

  updatePasswordFirstTime(data: any): Observable<any> {
    return this.https.put<any>(
      environment.apiUrl + "/user/user/1st", 
      data
    );
  }

  updateOwnPassword(data: any): Observable<any> {
    return this.https.put<any>(
      environment.apiUrl + "/user/user/own/password", 
      data
    );
  }
  
  updateOwnProfile(data: any): Observable<any> {
    return this.https.put<any>(
      environment.apiUrl + "/user/user/own/", 
      data
    );
  }

  updatePasswordFromEmail(data: any): Observable<any> {
    return this.https.post<any>(
      environment.apiUrl + "/user/api/reset", 
      data
    );
  }

  getOwnProfile(): Observable<any> {
    return this.https.get<any>(
      environment.apiUrl + "/user/user/own/", 
    );
  }

  otpRequest(): Observable<any> {
    return this.https.get<any>(
      environment.apiUrl + "/user/api/otp/", 
    );
  };

  otpForgetPasswordRequest(): Observable<any> {
    return this.https.get<any>(
      environment.apiUrl + "/user/api/reset/otp", 
    );
  };

  otpSubmit(data: any): Observable<any>{
    return this.https.post<any>(
      environment.apiUrl + "/user/api/otp/", 
      data
    );
  };

  sendForgetPassword(data: any): Observable<any>{
    return this.https.post<any>(
      environment.apiUrl + "/user/api/forget/", 
      data
    );
  };

  // No request to BE
  setUser(user: any): void {
    localStorage.setItem('username', user.username);
    localStorage.setItem('user', JSON.stringify(user));
    this.currentUser = user;
  }

  containsAnyRoles(roles: string[]): boolean {
    if (this.currentUser == null) {
      this.redirectToLogin();
    }
    else {
      if (this.currentUser.eikwa != undefined) {
        try {
          // console.log("containsAnyRoles user: ", this.currentUser.eikwa, roles);
          for (let i = 0; i < this.currentUser.eikwa.length; i++) {
            for (let j = 0; j < roles.length; j++) {
              if (this.currentUser.eikwa[i] == roles[j]) {
                return true;
              }
            }
          }
        } catch (error) {
          // console.log("containsAnyRoles user: ", error);
        }
      }
    }
    return false;
  }

  getUserInfo(): Observable<any> {
    let user = localStorage.getItem('user');   
    if (user == undefined || user == null || user == '') {
      this.redirectToLogin();
      return null;
    }
    else {
      return observableOf(JSON.parse(user));
    }
  }

  isAisUser(): boolean {
    if (this.currentUser == null) {
      this.redirectToLogin();
    }
    else {
      // console.log("isAisUser(): ", this.currentUser);
      if (this.currentUser.sia != undefined) {
        return true;
      }
    }
    return false;
  }

  redirectToLogin(): void {
    this.router.navigate(['/auth/login']);
    localStorage.removeItem("username");
    localStorage.removeItem("user");
    localStorage.removeItem("auth_app_token");
  }


}
