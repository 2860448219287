import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { ExtLinkData } from '../interface/ext-link';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http'; 

@Injectable()
export class ExtLinkService extends ExtLinkData {

  constructor(private https: HttpClient) { 
    super();
  }
  
  private listData = {
    code: "000",
    data: [
      {id: 1, name: 'eBizPortal', url: null, status: 'active', parent: null, params: [], seq: 1},
      {id: 2, name: 'SIEM', url: null, status: 'active', parent: null, params: [], seq: 2},
      {id: 3, name: 'Billing', url: "www.google.com", status: 'active', parent: null, params: [], seq: 3},
      {id: 4, name: 'eBiz 1', url: "www.google.com", status: 'active', parent: 1, params: [], seq: 1},
      {id: 5, name: 'eBiz 2', url: "www.google.com", status: 'inactive', parent: 1, params: [], seq: 2},
      {id: 6, name: 'eBiz 3', url: "www.google.com", status: 'active', parent: 1, params: [], seq: 3},
      {id: 7, name: 'SIEM 1', url: "www.google.com", status: 'active', parent: 2, params: [], seq: 1},
      {id: 8, name: 'SIEM 2', url: "www.google.com", status: 'inactive', parent: 2, params: [], seq: 2},
    ]
  };

  private listParent = {
    code: "000",
    data: [
      {id: 1, name: 'eBizPortal', url: null, status: 'active', parent: null, params: [], seq: 1},
      {id: 2, name: 'SIEM', url: null, status: 'active', parent: null, params: [], seq: 2},
      // {id: 3, name: 'Billing', url: null, status: 'active', parent: null, params: [], seq: 3},
    ]
  }

  // private getData = {
  //   code: "000",
  //   data: {
  //     id: 3, 
  //     name: 'Billing', 
  //     url: "www.google.com", 
  //     status: 'active', 
  //     parent: null, 
  //     params: [
  //       {id: 1, key: "company", value: "company", type: "runtime"},
  //       {id: 1, key: "startDate", value: "fromDate", type: "runtime"},
  //       {id: 1, key: "endDate", value: "toDate", type: "runtime"}
  //     ], 
  //     seq: 3
  //   }
  // }

  private updateData = {
    code: "000",
  }
	
  list(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/config/menu", 
    );
    // return observableOf(this.listData);
  }

	get(data: any): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/config/menu/" + data.id, 
    );
    // return observableOf(this.getData);
	}
	
	updateOrder(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/config/menu/swap", 
			data,
    );
    // return observableOf(this.updateData);
  }

  create(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/config/menu/create", 
			data,
    );
    // return observableOf(this.updateData);
	}
	
	update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/config/menu/" + data.id, 
			data,
    );
    // return observableOf(this.updateData);
	}
	
	delete(data: any): Observable<any> {
    return this.https.delete(
			environment.apiUrl + "/config/menu/" + data.id, 
    );
    // return observableOf(this.updateData);
  }

}
