import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParamData } from '../interface/param';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class ParamService extends ParamData {

  constructor(private https: HttpClient) { 
    super();
  }
	
	get(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/config/setting", 
		);
	}

	getPasswordLength(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/config/setting/pdl", 
		);
	}

  update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/config/setting", 
			data
		);
	}

  private getData = {
    code: "000",
    data: {
			publicIp: "192.168.1.0/24,10.10.0.0/16",
			privateIp: "192.168.2.0/24,10.10.0.0/16",
			casIp: "192.168.3.0/24,10.10.0.0/16",
			timeout: 100,
			passwordExpire: 99,
			emailNotification: 9,
			promotionUrl: "http://promotion.com",
			newsUrl: "http://news.com",
			schedule1: "12:12:00",
			schedule2: "00:03:00",
			schedule3: "18:09:00",
			useAuthenDb: true,
    }
  }

  private updateData = {
    code: "000",
  }

}
