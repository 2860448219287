import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ChartData } from '../interface/chart';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class ChartService extends ChartData {

  constructor(private https: HttpClient) { 
    super();
  }

  list(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/dashboard/chart", 
    );
  }

  listSetting(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/dashboard/chart/setting", 
    );
  }

	get(data: any): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/dashboard/chart/" + data.id, 
    );
	}

  create(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/dashboard/chart/create", 
			data,
    );
	}
	
	update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/dashboard/chart/" + data.id, 
			data,
    );
	}
	
	delete(data: any): Observable<any> {
    return this.https.delete(
			environment.apiUrl + "/dashboard/chart/" + data.id, 
    );
	}
	
	preview(data: any): Observable<any> {
    // return observableOf({});
    return this.https.post(
			environment.apiUrl + "/dashboard/chart/preview", 
			data,
    );
	}

}
