
import {
  NbComponentStatus,
} from '@nebular/theme';

export abstract class AppUtils {
	
	abstract showToast(type: NbComponentStatus, title: string, body: string, resp: any): void;
	abstract getTimestamp(): string;

}