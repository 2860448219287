import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MenuData } from '../interface/menu';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class MenuRestService extends MenuData {

  constructor(private https: HttpClient) { 
    super();
	}
	
	list(): Observable<any> {
		return this.https.get(
			environment.apiUrl + "/config/menu/list", 
    )
	}

}
