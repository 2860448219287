import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ReportData } from '../interface/report';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class ReportService extends ReportData {

  constructor(private https: HttpClient) { 
    super();
	}
	
	list(data:any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/report/report", 
			data,
    )
	}

	preview(data:any): Observable<any> {
		return this.https.get(
			environment.apiUrl + "/report/report/preview" + data.id, 
    )
	}

	downloadReport(file:any): Observable<any> {
		// Create url
		let url = `${environment.apiUrl}${"/report/report/download/"}${file}`;
		var body = { id: file };
		// var body = new FormData();
		// body.append('id', file);
		
		return this.https.post(url, body, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
	}

	get(data:any): Observable<any> {
		// return this.https.post(
		// 	environment.apiUrl + "/report/setting/get/" + data.id, 
		// 	data,
		// )
		return observableOf(this.getData);
	}

	listSetting(data:any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/report/setting", 
			data,
    )
	}

	previewSetting(data:any): Observable<any> {
		return this.https.get(
			environment.apiUrl + "/report/setting/preview/" + data.id, 
    )
	}

	publishSetting(data:any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/report/setting/publish/" + data.id, 
			data,
    )
	}

	deleteSetting(data:any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/report/setting/delete/" + data.id, 
			data,
    )
	}

	upload(params:any, data:any, observe: any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/report/setting/upload" + params, 
			data,
			observe,
    )
	}

	getManualSetting(data:any): Observable<any> {
		// return this.https.post(
		// 	environment.apiUrl + "/report/setting/get/" + data.id, 
		// 	data,
		// )
		return observableOf(this.getData);
	}

	saveDraftManualSetting(data:any): Observable<any> {
		// return this.https.post(
		// 	environment.apiUrl + "/report/setting/get/" + data.id, 
		// 	data,
		// )
		
		return observableOf(this.updateData);
	}

	publishManualSetting(data:any): Observable<any> {
		// return this.https.post(
		// 	environment.apiUrl + "/report/setting/get/" + data.id, 
		// 	data,
		// )
		return observableOf(this.updateData);
	}

	getData = {
		code: "000",
		data: {
			id: 1,
			name: "Event Log Report",
			header: "Header Part",
			log: "Log Consumption",
			customer: "CS Loxinfo",
			reportDate: "11/11/2019",
			executiveSummary: "<p>Test Data</p>",
			logo: null,
			events: [
				{
					id: 1,
					name: "Top 10 User log in failed.",
					chartConfig: {
						tooltip : {
							trigger: 'axis',
							axisPointer : { 
									type : 'shadow'
							},
							textStyle: {
								color: '#ffffff',
							},
						},
						legend: {
								data: ['Field 1'],
								textStyle: {
									color: "#ffffff",
								},
						},
						grid: {
								left: '3%',
								right: '4%',
								bottom: '3%',
								containLabel: true
						},
						xAxis: {
								type: 'category',
								boundaryGap: false,
								data: ['category2', 'category3', 'category4', 'category5', 'category6', 'category7', 'category8'],
								axisLabel: {
									color: "#ffffff",
								},
								axisLine: {
									lineStyle: {
										color: "#ffffff",
									},
								},
						},
						yAxis: {
								type: 'value',
								axisLabel: {
									color: "#ffffff",
								},
								axisLine: {
									lineStyle: {
										color: "#ffffff",
									},
								},
						},
						series: [
								{
										name: 'Field 1',
										type: 'line',
										stack: 'stack 1',
										data: [120, 132, 101, 134, 90, 230, 210]
								},
						]
					},
					content: "<p>Test 1</p>",
				},
				{
					id: 2,
					name: "Potential of DDoS Attack",
					chartConfig: {
						tooltip : {
							trigger: 'axis',
							axisPointer : { 
									type : 'shadow'
							},
							textStyle: {
								color: '#ffffff',
							},
						},
						legend: {
								data: ['Field 1'],
								textStyle: {
									color: "#ffffff",
								},
						},
						grid: {
								left: '3%',
								right: '4%',
								bottom: '3%',
								containLabel: true
						},
						xAxis: {
								type: 'category',
								boundaryGap: false,
								data: ['category2', 'category3', 'category4', 'category5', 'category6', 'category7', 'category8'],
								axisLabel: {
									color: "#ffffff",
								},
								axisLine: {
									lineStyle: {
										color: "#ffffff",
									},
								},
						},
						yAxis: {
								type: 'value',
								axisLabel: {
									color: "#ffffff",
								},
								axisLine: {
									lineStyle: {
										color: "#ffffff",
									},
								},
						},
						series: [
								{
										name: 'Field 1',
										type: 'line',
										stack: 'stack 1',
										data: [120, 132, 101, 134, 90, 230, 210]
								},
						]
					},
					content: "<p>Test 2</p>",
				},
			]
		}
	}

	updateData = {
		code: "000",
		data: {},
	}

}
