import { Observable } from 'rxjs';

export abstract class ChartData {
	abstract list(): Observable<any>;
	abstract listSetting(): Observable<any>;
	abstract create(data:any): Observable<any>;
	abstract update(data:any): Observable<any>;
	abstract get(data:any): Observable<any>;
	abstract delete(data:any): Observable<any>;
	abstract preview(data:any): Observable<any>;
	

	//******** Create Chart View **********/

	defaultTableSettings: any = {
    actions: false,
    columns: {
      xAxis: {

      }
    },
  }

	parseChartConfig(chart:any, data:any): any {
		// console.log("parseChartConfig(): ", chart, data);
		chart.chartConfig = data;
		chart.tableSettings = this.defaultTableSettings;
		chart.tableData = [];

		if ( chart.chartConfig != null) {
			if (["line", "area", "column", "bar", "combine", "lineMulti", "lineMultiStack", "columnMulti", "columnMultiStack", "barMulti", "barMultiStack"].indexOf(chart.chart.type) > -1) {
				chart.tableSettings.columns = {};
				let newSettings = chart.tableSettings;
				newSettings.columns.xAxis = {
					title: chart.chart.x.name,
					type: 'string',
				}
				if (["lineMulti", "lineMultiStack", "barMulti", "barMultiStack", "columnMulti", "columnMultiStack"].indexOf(chart.chart.type) > -1) {
					// console.log("extend width first column");
					newSettings.columns.xAxis = {
						title: chart.chart.x.name,
						type: 'string',
						width: '300px',
					}
				}
				let cols = [];
				chart.chartConfig.series.forEach((serie, idx) => {
					let colKey = "series_" + idx;
					newSettings.columns[colKey] = {
						title: serie.name,
						type: 'string',
					}
					let colRow = {};
					colRow["key"] = colKey;
					colRow["data"] = serie.data;
					cols.push(colRow);
				});
				chart.tableSettings = Object.assign({}, newSettings );
				// console.log("previewChart() tablePreviewSettings", chart.tableSettings);
	
				if (["line", "area", "column", "combine", "lineMulti", "lineMultiStack", "columnMulti", "columnMultiStack"].indexOf(chart.chart.type) > -1) {
					chart.chartConfig.xAxis.data.forEach((data, idx) => {
						let row = {};
						row["xAxis"] = data;
						cols.forEach((col, idx2) => {
							row[col.key] = col.data[idx];
							// row[col] = previewData.series[idx].data[idx2];
						});
						chart.tableData.push(row);
					});
				}
				else { //bar
					chart.chartConfig.yAxis.data.forEach((data, idx) => {
						let row = {};
						row["xAxis"] = data;
						cols.forEach((col, idx2) => {
							row[col.key] = col.data[idx];
							// row[col] = previewData.series[idx].data[idx2];
						});
						chart.tableData.push(row);
					});
				}
			}
			if (["scatter"].indexOf(chart.chart.type) > -1) {
				chart.tableSettings.columns = {};
				let newSettings = chart.tableSettings;
				newSettings.columns.xAxis = {
					title: chart.chart.x.name,
					type: 'string',
				}
				newSettings.columns.yAxis = {
					title: chart.chart.y.name,
					type: 'string',
				}
				newSettings.columns.zAxis = {
					title: chart.chart.z.name,
					type: 'string',
				}
				chart.tableSettings = Object.assign({}, newSettings );
	
				chart.chartConfig.series[0].data.forEach((data, idx) => {
					let row = {};
					row["xAxis"] = data[2];
					row["yAxis"] = data[0];
					row["zAxis"] = data[1];
					chart.tableData.push(row);
				});
			}
			if (["bubble"].indexOf(chart.chart.type) > -1) {
				chart.tableSettings.columns = {};
				let newSettings = chart.tableSettings;
				newSettings.columns.xAxis = {
					title: chart.chart.x.name,
					type: 'string',
				}
				newSettings.columns.yAxis = {
					title: chart.chart.y.name,
					type: 'string',
				}
				newSettings.columns.zAxis = {
					title: chart.chart.z.name,
					type: 'string',
				}
				newSettings.columns.aAxis = {
					title: chart.chart.a.name,
					type: 'string',
				}
				chart.tableSettings = Object.assign({}, newSettings );
	
				chart.chartConfig.series[0].data.forEach((data, idx) => {
					let row = {};
					row["xAxis"] = data[3];
					row["yAxis"] = data[0];
					row["zAxis"] = data[1];
					row["aAxis"] = data[2];
					chart.tableData.push(row);
				});
			}
			if (["pie", "funnel", "pyramid"].indexOf(chart.chart.type) > -1) {
				chart.tableSettings.columns = {};
				let newSettings = chart.tableSettings;
				newSettings.columns.xAxis = {
					title: chart.chart.x.name,
					type: 'string',
				}
				newSettings.columns.yAxis = {
					title: chart.chart.y.name,
					type: 'string',
				}
				chart.tableSettings = Object.assign({}, newSettings );
	
				chart.chartConfig.series[0].data.forEach((data, idx) => {
					let row = {};
					row["xAxis"] = data.name;
					row["yAxis"] = data.value;
					chart.tableData.push(row);
				});
			}
		}
		if (["network"].indexOf(chart.chart.type) > -1) {
			chart.tableSettings.columns = {};
			let newSettings = chart.tableSettings;
			newSettings.columns.xAxis = {
				title: "Node A",
				type: 'string',
			}
			newSettings.columns.yAxis = {
				title: "Node Z",
				type: 'string',
			}
			chart.tableSettings = Object.assign({}, newSettings );

			chart.chartConfig.series[0].links.forEach((data, idx) => {
				let row = {};
				let rowdata = chart.chartConfig.series[0].data;
				if (rowdata != null) {
					row["xAxis"] = rowdata[data.source].name;
					row["yAxis"] = rowdata[data.target].name;
					chart.tableData.push(row);
				}
			});
		}

		return chart;
	}

}