import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbDummyAuthStrategy, NbPasswordAuthStrategy, NbAuthSimpleToken } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
} from './utils';
// import { UserData } from './data/users';
import { UserData2 } from './interface/users2';


import { AssetData } from './interface/asset';
import { AttributeData } from './interface/attribute';
import { CompanyData } from './interface/company';
import { DashboardData } from './interface/dashboard';
import { UsageData } from './interface/usage';
import { ReportData } from './interface/report';
import { MenuData } from './interface/menu';
import { ChartData } from './interface/chart';
import { DatasetData } from './interface/dataset';
// import { UserData } from './interface/user';
import { UserRoleData } from './interface/user-role';
import { ExtLinkData } from './interface/ext-link';
import { ContentData } from './interface/content';
import { ParamData } from './interface/param';

import { AppUtils } from './interface/utils';

import { AssetService } from './service/asset.service';
import { AttributeService } from './service/attribute.service';
import { UserService2 } from './service/users.service2';
import { CompanyService } from './service/company.service';
import { ChartService } from './service/chart.service';
import { DashboardService } from './service/dashboard.service';
import { UsageService } from './service/usage.service';
import { ReportService } from './service/report.service';
import { MenuRestService } from './service/menu-rest.service';
import { DatasetService } from './service/dataset.service';
import { AppUtilsService } from './service/utils.service';
import { UserRoleService } from './service/user-role.service';
import { ExtLinkService } from './service/ext-link.service';
import { ContentService } from './service/content.service';
import { ParamService } from './service/param.service';

// import { ChartService } from './csoc-mock/chart.service';
// import { DatasetService } from './csoc-mock/dataset.service';
// import { CompanyService } from './csoc-mock/company.service';
// import { UserService } from './csoc-mock/user.service';
// import { UserRoleService } from './csoc-mock/user-role.service';

export const USER_PICTURE = 'assets/images/user.png';


const socialLinks = [
  // {
  //   url: 'https://github.com/akveo/nebular',
  //   target: '_blank',
  //   icon: 'github',
  // },
  // {
  //   url: 'https://www.facebook.com/akveo/',
  //   target: '_blank',
  //   icon: 'facebook',
  // },
  // {
  //   url: 'https://twitter.com/akveo_inc',
  //   target: '_blank',
  //   icon: 'twitter',
  // },
];

const DATA_SERVICES = [
  { provide: AssetData, useClass: AssetService },
  { provide: AttributeData, useClass: AttributeService },
  // { provide: UserData, useClass: UserService },
  { provide: UserData2, useClass: UserService2 },
  { provide: CompanyData, useClass: CompanyService },
  { provide: DashboardData, useClass: DashboardService },
  { provide: UsageData, useClass: UsageService },
  { provide: ReportData, useClass: ReportService },
  { provide: MenuData, useClass: MenuRestService },
  { provide: ChartData, useClass: ChartService },
  { provide: DatasetData, useClass: DatasetService },
  { provide: UserRoleData, useClass: UserRoleService },
  { provide: AppUtils, useClass: AppUtilsService },
  { provide: ExtLinkData, useClass: ExtLinkService },
  { provide: ContentData, useClass: ContentService },
  { provide: ParamData, useClass: ParamService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      // NbDummyAuthStrategy.setup({
      //   name: 'email',
      //   delay: 3000,
      // }),
      // NbPasswordAuthStrategy.setup({
      //   name: 'email',

      //   baseEndpoint: 'http://localhost:8080/csoc-server',
      //   login: {
      //     endpoint: '/api/logon',
      //     method: 'post',
      //     redirect: {
      //       success: '/',
      //       failure: null, // stay on the same page
      //     },
      //   },
      //   token: {
      //     class: NbAuthSimpleToken,
      //     key: 'access_token', // this parameter tells where to look for the token
      //   },
      //   validation: {
      //     email: {
      //       required: false,
      //     }
      //   }
      // }),
    ],
    forms: {
      // login: {
      //   socialLinks: socialLinks,
      // },
      // register: {
      //   socialLinks: socialLinks,
      // },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
