import { Observable } from 'rxjs';


export abstract class AttributeData {
  abstract list(): Observable<any>;
	abstract create(data:any): Observable<any>;
	abstract update(data:any): Observable<any>;
	abstract get(data:any): Observable<any>;
	abstract delete(data:any): Observable<any>;
	abstract listPattern(): Observable<any>;
	abstract querySql(data:any): Observable<any>;
	abstract swap(data:any): Observable<any>;
}
