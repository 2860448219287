import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AttributeData } from '../interface/attribute';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class AttributeService extends AttributeData {

  constructor(private https: HttpClient) { 
    super();
  }

  list(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/asset/field", 
    );
    // return observableOf(this.listData);
  }

	get(data: any): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/asset/field/" + data.id, 
    );
    // return observableOf(this.getData);
	}

  create(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/asset/field/create", 
			data,
    );
    // return observableOf(this.updateData);
	}
	
	update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/asset/field/" + data.id, 
			data,
    );
    // return observableOf(this.updateData);
	}
	
	delete(data: any): Observable<any> {
    return this.https.delete(
			environment.apiUrl + "/asset/field/" + data.id, 
    );
    // return observableOf(this.updateData);
  }

  listPattern(): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/asset/field/pattern", 
    );
  }

  querySql(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/asset/field/sql", 
			data,
    );
  }
  
  swap(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/asset/field/swap", 
			data,
    );
	}

  private listData = {
    code: "000",
    data: [
      {id: 1, name: 'Asset Name', status: 'active', fieldType: "text", valueType: "String", mandatory: true, seq: 1},
      {id: 2, name: 'Status', status: 'active', fieldType: "select", valueType: "String", mandatory: true, seq: 2},
      {id: 2, name: 'Counter', status: 'active', fieldType: "radio", valueType: "boolean", mandatory: true, seq: 3},
    ]
  };

  private getData = {
    code: "000",
    data: {
      id: 1, 
      name: "Asset Name",
      seq: 1,
      status: 'active', 
      fieldType: "text", 
      valueType: "String", 
      mandatory: true,
      length: 99999,
      key: false,
      multiple: false,
      validate: true,
      validatePattern: { id: 1 },
      suggestion: "Asset Name",
      masking: false,
      maskingType: null,  //No Display, Name, Email, MobileNo, IP
      hasOther: false,
      sql: false,
      queryString: null,
      structure: null,
      choices: [],
    }
  }

  private updateData = {
    code: "000",
  }

}
