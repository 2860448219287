import { Observable } from 'rxjs';

export abstract class UserData2 {
  
  abstract resetPassword(data: any): Observable<any>;
  abstract updateMobile(data: any): Observable<any>;
  abstract searchAisUser(data: any): Observable<any>;
  abstract searchNonAisUser(data: any): Observable<any>;
  abstract get(data: any): Observable<any>;
  abstract create(data: any): Observable<any>;
  abstract update(data: any): Observable<any>;
  abstract delete(data: any): Observable<any>;
  abstract getEdit(data: any): Observable<any>;
  abstract exportAis(data: any, filetype: any): Observable<any>;
	abstract exportCustomer(data: any, filetype: any): Observable<any>;

  // Own Service
  abstract logon(data: any): Observable<any>;
  abstract updatePasswordFirstTime(data: any): Observable<any>;
  abstract updatePasswordFromEmail(data: any): Observable<any>;
  abstract updateOwnPassword(data: any): Observable<any>;
  abstract updateOwnProfile(data: any): Observable<any>;
  abstract getOwnProfile(): Observable<any>;
  abstract otpRequest(): Observable<any>;
  abstract otpSubmit(data: any): Observable<any>;
  abstract otpForgetPasswordRequest(): Observable<any>;
  abstract sendForgetPassword(data: any): Observable<any>;

  // No Req
  abstract setUser(user: any): void;
  abstract containsAnyRoles(roles: string[]): boolean;
  abstract getUserInfo(): Observable<any>;
  abstract isAisUser(): boolean;
  abstract redirectToLogin(): void;
}