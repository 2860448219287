import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AssetData } from '../interface/asset';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class AssetService extends AssetData {

  constructor(private https: HttpClient) { 
    super();
  }
	
	fieldList(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/asset/asset/fields", 
		);
	}

	lastUpdated(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/asset/field/lastUpdated", 
		);
	}

  quickSearch(data: any, pageIndex:any, pageSize:any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/asset/asset?page=" + (pageIndex-1) + "&size=" + pageSize, 
			data
		);
	}

	advanceSearch(data: any, pageIndex:any, pageSize:any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/asset/asset/advance?page=" + (pageIndex-1) + "&size=" + pageSize, 
			data
		);
	}

	get(data: any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/asset/asset/" + data.id , 
			data
		);
	}

  create(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/asset/asset/create", 
			data,
    );
	}

  update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/asset/asset/" + data.id, 
			data,
    );
	}

	delete(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/asset/asset/delete/" + data.id, 
			data
    );
  }
	
  getEdit(data: any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/asset/asset/edit/" + data.id , 
			data
		);
	}

	export(data: any): Observable<any> {
		let url = `${environment.apiUrl}${"/asset/job/export/data"}`;
		return this.https.post(url, data, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
	}
	
	exportTemplate(data: any): Observable<any> {
		let url = `${environment.apiUrl}${"/asset/job/export/template"}`;
		return this.https.post(url, data, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
	}

	exportQuick(data: any, filetype: any): Observable<any> {
		let url = `${environment.apiUrl}${"/asset/asset/export/"}${filetype}`;
		return this.https.post(url, data, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
	}

	exportAdvance(data: any, filetype: any): Observable<any> {
		let url = `${environment.apiUrl}${"/asset/asset/advance/export/"}${filetype}`;
		return this.https.post(url, data, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
	}

	import(formData: any): Observable<any> {
		let url = environment.apiUrl + '/asset/job/import';
		let token = JSON.parse(localStorage.getItem('auth_app_token'));    
    return this.https.post(url, 
      formData, 
      {
				headers: { "username": localStorage.getItem("username"), "sessionId": token.value },
        reportProgress: true,
        observe: 'events'  
      }
    )
	}
	
	jobList(data): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/asset/job", 
			data
		);
	}
	
	getJob(data: any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/asset/job/" + data.id, 
			data
		);
	}
	
	confirmJob(data: any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/asset/job/confirm/" + data.id, 
			data
		);
	}

	discardJob(data: any): Observable<any> {
		return this.https.post(
			environment.apiUrl + "/asset/job/discard/" + data.id, 
			data
		);
	}

	downloadJobResult(data: any): Observable<any> {
		let url = `${environment.apiUrl}${"/asset/job/result/"}${data.id}`;
		return this.https.post(url, data, {
			responseType: "blob",
			headers: new HttpHeaders().append("Content-Type", "application/json"),
			observe: 'response'
		});
	}

  private listData = {
    code: "000",
    data: [
      
    ]
  };

  private getData = {
    code: "000",
    data: {
      
    }
  }

  private updateData = {
    code: "000",
  }

}
