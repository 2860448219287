import { Component, OnDestroy, AfterViewInit, Output, EventEmitter, ElementRef, Input, SimpleChanges } from '@angular/core';
import { LocationStrategy } from '@angular/common';

@Component({
  selector: 'ngx-tiny-mce',
  template: '',
})
export class TinyMCEComponent implements OnDestroy, AfterViewInit {

  @Output() editorKeyup = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();

  content: any = null;
  editor: any;

  constructor(
    private host: ElementRef,
    private locationStrategy: LocationStrategy,
  ) { 

  }

  ngAfterViewInit() {
    tinymce.init({
      target: this.host.nativeElement,
      plugins: ['link', 'paste', 'table'],
      skin_url: `${this.locationStrategy.getBaseHref()}assets/skins/lightgray`,
      setup: editor => {
        this.editor = editor;
        editor.on('keyup', () => {
          this.editorKeyup.emit(editor.getContent());
        });
        editor.on('change', () => {
          this.valueChange.emit(editor.getContent());
        });
      },
      height: '320',
    });
  }

  @Input() setContent(content: any) {
    // console.log(`Set Content:`, this.editor, content);
    // this.editor.setContent(content);
    this.content = content;
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }
}
