import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import {formatDate } from '@angular/common';

import { UserData2 } from '../../../@core/interface/users2';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { USER_PICTURE } from '../../../@core/core.module';
import { HttpClient } from '@angular/common/http'; 
import { Router } from '@angular/router';
import { ContextMenuService, ContextMenuComponent } from 'ngx-contextmenu';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  userPicture: any;
  user: any;
  fullname: string = "";
  lastLogin: string = "";

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  isAisUser = false;

  userMenu = [
    { title: 'Log out', link: '/auth/logout'},
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService2: UserData2,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private https: HttpClient,
              private router: Router,
              private contextMenuService: ContextMenuService,
  ) {
   
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.https.get('assets/images/user.png', { responseType: 'blob' })
      .subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.userPicture = reader.result;   
          // console.log("userPicture: ", this.userPicture);             
        }
        reader.readAsDataURL(res); 
        // console.log(res);
      });

    this.userService2.getUserInfo().subscribe(
      user => {
        this.user = user;
        this.fullname = `${user.first_name} ${user.last_name}`;
        this.lastLogin = "Last login: " + user.last_login;
        // console.log("user: ", user);
      },
      err => {
        // console.log("err: ", err);
      }
    );

    if (this.userService2.isAisUser()) {
      this.isAisUser = true;
      // this.userMenu = [
      //   { title: 'Log out', link: '/auth/logout'},
      // ]
    }
    // else {
    //   this.userMenu = [
    //     { title: 'Update Profile', link: '/auth/update-profile'},
    //     { title: 'Change Password', link: '/auth/reset-password'}, 
    //     { title: 'Log out', link: '/auth/logout'},
    //   ]
    // }

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  updateProfile() {
    this.router.navigate(['/auth/update-profile']);
  }

  changePassword() {
    this.router.navigate(['/auth/reset-password']);
  }

  logout() {
    this.router.navigate(['/auth/logout']);
  }

  // @ViewChild('userAction', null) userAction;
  @ViewChild('profileMenu', null) profileMenu: ContextMenuComponent;

  public onContextMenu($event: KeyboardEvent, item: any): void {
    var userAction = document.getElementById('userAction');
    // console.log("event.target: ", event.target, userAction);
    this.contextMenuService.show.next({
      anchorElement: userAction,
      contextMenu: this.profileMenu,
      event: <any>$event,
      item: item,
    });
    $event.preventDefault();
    $event.stopPropagation();
  }
  
}
