import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserRoleData } from '../interface/user-role';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class UserRoleService extends UserRoleData {

  constructor(private https: HttpClient) { 
    super();
  }

  list(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/user/role", 
    );
  }

  listAis(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/user/role/ais", 
    );
  }

  listNonAis(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/user/role/customer", 
    );
  }

  listPriviledge(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/user/role/prvlge", 
    );
  }

  search(data: any): Observable<any> {
    return this.https.post(
      environment.apiUrl + "/user/role/search", 
      data
    );
  }

	get(data: any): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/user/role/" + data.id, 
    );
	}

  create(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/user/role/create", 
			data,
    );
	}
	
	update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/user/role/" + data.id, 
			data,
    );
	}
	
	delete(data: any): Observable<any> {
    return this.https.delete(
			environment.apiUrl + "/user/role/" + data.id, 
    );
  }

  export(fileType): Observable<any> {
		// Create url
    let url = `${environment.apiUrl}${"/user/role/export/"}${fileType}`;
		var body = { };
		return this.https.post(url, body, {
			responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json"),
      observe: 'response'
		});
	}

}
