import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DashboardData } from '../interface/dashboard';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class DashboardService extends DashboardData {

  constructor(private https: HttpClient) { 
    super();
	}

	//******************** New ****************************/

	updatePreference(data:any): Observable<any>{
		return this.https.put(
			environment.apiUrl + "/dashboard/preference/" + data.id, 
			data,
    )
	}

	getDashboard(data:any): Observable<any>{
		return this.https.post(
			environment.apiUrl + "/dashboard/dashboard/" + data.id , 
			data,
    )
	}

	getDefaultDashboard(data:any): Observable<any>{
		return this.https.post(
			environment.apiUrl + "/dashboard/dashboard", 
			data,
    )
	}

	getTotalIncident(data:any): Observable<any>{
		return this.https.post(
			environment.apiUrl + "/dashboard/dashboard/queryTotalIncident", 
			data,
    )
	}

	getTickets(data:any): Observable<any>{
		return this.https.post(
			environment.apiUrl + "/dashboard/dashboard/queryTicket", 
			data,
    )
	}

	getTicketToken(data:any): Observable<any>{
		return this.https.post(
			environment.apiUrl + "/dashboard/dashboard/ticket", 
			data,
		)
		// let mockData: any = {
		// 	code: "000",
		// 	data: {token: "xvcsadfadsfsfds"}
		// }
		// return observableOf(mockData); 
	}

}
