import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ContentData } from '../interface/content';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class ContentService extends ContentData {

  constructor(private https: HttpClient) { 
    super();
  }
	
	get(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/content", 
		);
		// return observableOf(this.getData);
	}

  updatePromotion(): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/content/promotion", 
		);
	}

	updatePR(): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/content/pr", 
		);
	}

  private getData = {
    code: "000",
    data: {
			promotion : [
				{"picture": "https://business.ais.co.th/onspotpackage/images/herobanner/maintopup-desktop.jpg", "link": "https://business.ais.co.th/on-spot-package.html?intcid=home-th-herobanner-onspotpackage"},
				{"picture": "https://business.ais.co.th/surafaceLTE/images/herobanner/herobanner_pc.jpg", "link": "http://business.ais.co.th/surafaceLTE/"},
				{"picture": "https://business.ais.co.th/aisebizshop/herobanner/AISeBizshop-desktop-button.jpg", "link": "https://business.ais.co.th/aisebizshop/"},
				{"picture": "https://business.ais.co.th/assets/revamp/img/full-width-slides/csl-desktop.jpg", "link": "https://www.csl.co.th/csl/"},
				{"picture": "https://business.ais.co.th/assets/revamp/img/full-width-slides/300-desktop-home.jpg", "link": "http://business.ais.co.th/crm"},
				{"picture": "https://business.ais.co.th/assets/revamp/img/full-width-slides/frost&sullivan-desktop-th.png", "link": "http://business.ais.co.th/news-activities/frost&sullivan.html"},
				{"picture": "https://business.ais.co.th/assets/revamp/img/full-width-slides/ebusinessportal_desktop.jpg", "link": "https://ebusinessportal.ais.co.th"},
			],
      pr : [
				{
					"picture": "https://business.ais.co.th/assets/revamp/img/carousel/enterprise_iot.jpg", 
					"header": "AIS IoT", 
					"detail": "ตอบโจทย์ธุรกิจด้วยบริการ AIS IoT และ Ecosystems", 
					"button": "เพิ่มเติม",
					"link": "https://business.ais.co.th/iot/?intcid=home-th-enterprisebanner-iot"
				},
				{
					"picture": "https://business.ais.co.th/assets/revamp/img/carousel/enterprise_datacenter.jpg", 
					"header": "DATA CENTER", 
					"detail": "มาตรฐานระดับโลก มั่นใจ ปลอดภัยสูงสุด", 
					"button": "เพิ่มเติม",
					"link": "https://business.ais.co.th/solution/data-center.html?intcid=home-th-enterprisebanner-datacenter"
				},
				{
					"picture": "https://business.ais.co.th/assets/revamp/img/carousel/enterprise_cloud.jpg", 
					"header": "AIS BUSINESS CLOUDs", 
					"detail": "เชื่อมโยงทุกความสำเร็จแบบครบวงจร พร้อมความปลอดภัยสูงสุด", 
					"button": "เพิ่มเติม",
					"link": "https://business.ais.co.th/cloud/index.html?intcid=home-th-enterprisebanner-cloud"
				},
				{
					"picture": "https://business.ais.co.th/assets/revamp/img/carousel/enterprise_package.jpg", 
					"header": "ONTOP PACKAGE", 
					"detail": "แพ็กเกจเสริมพร้อมใช้ 5GB 150 บาท นาน 7 วัน", 
					"button": "ซื้อเลย",
					"link": "https://m.ais.co.th/DeHnB3s8?key=ATjTLQTWsBbX9n1gl%2FTDyMb0oqiZZUS7o5Bg2C4pJGU%3D&controller=userInteraction&lang=th&site=CM-FE-401&intcid=business-th-banner-5GB_7Days_150Baht"
				},
			],

    }
  }

  private updateData = {
    code: "000",
  }

}
