import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { DatasetData } from '../interface/dataset';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http'; 

@Injectable()
export class DatasetService extends DatasetData {

  constructor(private https: HttpClient) { 
    super();
	}
	
  list(): Observable<any> {
    return this.https.get(
      environment.apiUrl + "/dashboard/dataSet", 
    );
  }

	get(data: any): Observable<any> {
    return this.https.get(
			environment.apiUrl + "/dashboard/dataSet/" + data.id, 
    );
	}
	
	preview(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/dashboard/dataSet/testApi", 
			data,
    );
  }

  create(data: any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/dashboard/dataSet/create", 
			data,
    );
	}
	
	update(data: any): Observable<any> {
    return this.https.put(
			environment.apiUrl + "/dashboard/dataSet/" + data.id, 
			data,
    );
	}
	
	delete(data: any): Observable<any> {
    return this.https.delete(
			environment.apiUrl + "/dashboard/dataSet/" + data.id, 
    );
  }
}
