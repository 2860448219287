import { Observable } from 'rxjs';


export abstract class UserRoleData {
	abstract list(): Observable<any>;
	abstract listAis(): Observable<any>;
	abstract listNonAis(): Observable<any>;
	abstract listPriviledge(): Observable<any>;
	abstract search(data:any): Observable<any>;
	abstract create(data:any): Observable<any>;
	abstract update(data:any): Observable<any>;
	abstract get(data:any): Observable<any>;
	abstract delete(data:any): Observable<any>;
	abstract export(fileType): Observable<any>;
}
