import { Observable } from 'rxjs';

export abstract class ReportData {
  abstract list(data:any): Observable<any>;
  abstract preview(data:any): Observable<any>;
  abstract downloadReport(file:any): Observable<any>;
  abstract get(data:any): Observable<any>;

  abstract upload(params:any, data:any, observe: any): Observable<any>;
  abstract listSetting(data:any): Observable<any>;
  abstract previewSetting(data:any): Observable<any>;
  abstract publishSetting(data:any): Observable<any>;
  abstract deleteSetting(data:any): Observable<any>;

  abstract getManualSetting(data:any): Observable<any>;
  abstract saveDraftManualSetting(data:any): Observable<any>;
  abstract publishManualSetting(data:any): Observable<any>;

}