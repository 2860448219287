import {Injectable, Injector} from '@angular/core';
import {HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import { of } from "rxjs";
import {NbAuthSimpleToken, NbAuthService} from '@nebular/auth';
import { tap, catchError } from "rxjs/operators";
import { Router } from '@angular/router';

/**
 * TokenInterceptor
 * @see https://angular.io/guide/http#intercepting-all-requests-or-responses
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    private authService: NbAuthService;
    private tokenService: NbAuthSimpleToken;

    constructor(private injector: Injector, private router: Router) {
    }

    public getToken(): string {
        return localStorage.getItem('auth_app_token');
		}
		
		public getUsername(): string {
			return localStorage.getItem('username');
		}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.authService = this.injector.get(NbAuthService); // get it here within intercept

        this.authService.isAuthenticated().subscribe((result) => {
            if (result) {
							
						}
				});

				// console.log("request: ", request);
				// console.log("getToken: ", this.getToken());
				if (this.getToken() != null && !request.url.endsWith("logon")) {
					let token = JSON.parse(this.getToken());

					let username = this.getUsername();
					let customReq: any;
					if (username != null) {
						customReq = request.clone({
							headers: new HttpHeaders({
								// 'Bluetoken':  token.value,
								'sessionId': token.value,
								'username': username,
							})
						});
					}
					else {
						customReq = request.clone({
							headers: new HttpHeaders({
								// 'Bluetoken':  token.value,
								'sessionId': token.value,
							})
						});
					}

					return next.handle(customReq).pipe(
						tap(evt => {
							// console.log("evt: ", evt);
							if (request.url.endsWith('logoff')) {
								this.redirectToLogin();
							}
						}),
            catchError((error: HttpErrorResponse) => {
							// console.log("HttpErrorResponse: ", error)
							if (error.status == 401) {
								// console.log("No Authorize!!!")
								this.redirectToLogin();
								return;
							}
							else if (error.status == 500 || error.status == 0) {
								// console.log("Server Error!!!")
								if(!error.url.includes("/queryGraph")){
									this.router.navigate(['/pages/error']);
								}
								return Observable.throw(error);
							}
							// else if (error.status == 999) {
							// 	this.router.navigate(['/auth/reset-password']);
							// }
							else 
							{
								return Observable.throw(error);
							}
						})
					);
				}
				else {
					return next.handle(request);
				}
		}
		
		redirectToLogin() {
			// console.log("redirectToLogin: ", window.location.href);
			this.router.navigate(['/auth/login']);
			localStorage.removeItem("username");
			localStorage.removeItem("user");
			localStorage.removeItem("auth_app_token");
		}

}