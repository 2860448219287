import { Observable } from 'rxjs';

export abstract class AssetData {
	
	abstract fieldList(): Observable<any>;
	abstract lastUpdated(): Observable<any>;
  abstract quickSearch(data: any, pageIndex:any, pageSize:any): Observable<any>;
  abstract advanceSearch(data: any, pageIndex:any, pageSize:any): Observable<any>;
  abstract get(data: any): Observable<any>;
  abstract create(data: any): Observable<any>;
  abstract update(data: any): Observable<any>;
  abstract delete(data: any): Observable<any>;
  abstract getEdit(data: any): Observable<any>;
	abstract export(data: any): Observable<any>;
	abstract exportTemplate(data: any): Observable<any>;
	abstract exportQuick(data: any, filetype: any): Observable<any>;
	abstract exportAdvance(data: any, filetype: any): Observable<any>;
	abstract import(formData: any): Observable<any>;
	abstract jobList(data): Observable<any>;
	abstract getJob(data: any): Observable<any>;
	abstract confirmJob(data: any): Observable<any>;
	abstract discardJob(data: any): Observable<any>;
	abstract downloadJobResult(data: any): Observable<any>;

}