import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UsageData } from '../interface/usage';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class UsageService extends UsageData {

  constructor(private https: HttpClient) { 
    super();
  }
	
	getLicenseUsageData(data:any): Observable<any> {
    return this.https.post(
			environment.apiUrl + "/dashboard/threshold/queryGraph", 
			data,
    )
	}
}