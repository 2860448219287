import { Injectable } from '@angular/core';
import { AppUtils } from '../interface/utils';
import * as moment from 'moment';

import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from '@nebular/theme';

@Injectable()
export class AppUtilsService extends AppUtils {

  constructor(
		private toastrService: NbToastrService,
	) { 
    super();
	}

	showToast(type: NbComponentStatus, title: string, body: string, resp: any): void {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 5000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
    };

    if (resp != null) {
      if ( resp.error != null && resp.error.message != null ) {
        body = resp.error.message;
      }
      else {
        body = 'Please contact system admin.'
      }
    }

    const titleContent = title ? `${title}` : '';
    
    this.toastrService.show(
      body,
      titleContent,
      config);
  }

  getTimestamp(): string {
    var today = moment();
    var formattedDate = today.format('YYYYMMDDHHmmss');
    // let timestamp: string = "";
    return formattedDate;
  }
	
}
