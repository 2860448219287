import { Observable } from 'rxjs';

export abstract class DashboardData {
 
  abstract updatePreference(data:any): Observable<any>;
  abstract getDashboard(data:any): Observable<any>;
  abstract getDefaultDashboard(data:any): Observable<any>;
  abstract getTotalIncident(data:any): Observable<any>;
  abstract getTickets(data:any): Observable<any>;
  abstract getTicketToken(data:any): Observable<any>;
  
}